<template>
	<div class="page">
		<div class="service_banner relative">
			<img class="img" :src="bannerObj.imageUrl || require('../assets/static/jiaoyi_bg.png')"/>
			<div class="title">
				<div class="fontsize48 colorwhite">{{bannerObj.title || ""}}</div>
				<div class="desc fontsize18 colorwhite">{{bannerObj.subtitle || ""}}</div>
			</div>
		</div>
		<!-- 条件选择 -->
		<div class="deal_select_type relative w1239">
			<div class="inputdiv">
				<input class="fontsize12" v-model="formobj.title" placeholder="请输入标题搜索"/>
				<div class="seabtn" @click="handleBtn('init')"><i class="el-icon-search"></i></div>
			</div>
			<div class="sel_title fontsize14">已选择：</div>
			<div class="sel_item fontsize14" v-if="selectobj.rightName || selectobj.technicalFieldName || selectobj.legalName || selectobj.overTimeName || selectobj.productTypeName">
				<span v-if="selectobj.rightName">{{selectobj.rightName}}，</span>
				<span v-if="selectobj.technicalFieldName">{{selectobj.technicalFieldName}}，</span>
				<span v-if="selectobj.legalName">{{selectobj.legalName}}，</span>
				<span v-if="selectobj.overTimeName">{{selectobj.overTimeName}}</span>
				<span v-if="selectobj.productTypeName">{{selectobj.productTypeName}}</span>
			</div>
			<div class="sel_item fontsize14" v-else>全部</div>
		</div>
		<div class="deal_type_box">
			<div class="deal_typelist w1239 fontsize16">
				<div class="type_item">
					<div class="title">知识产权类型：</div>
					<div class="sel_item" @click="handleType('rightId',null,null)" :class="{'hover':formobj.rightId==null}">全部</div>
					<div class="sel_item" @click="handleType('rightId',item.id,item.name)" :class="{'hover':formobj.rightId==item.id}" v-for="(item,index) in rightType" :key="index">
					    {{item.name}}
					</div>
				</div>
				<!-- <div class="type_item">
					<div class="title">产业分类：</div>
					<div class="sel_item" @click="handleType('technicalFieldId',null,null)" :class="{'hover':formobj.technicalFieldId==null}">全部</div>
					<div class="sel_item" @click="handleType('technicalFieldId',item.id,item.name)" :class="{'hover':formobj.technicalFieldId==item.id}" v-for="(item,index) in transactionType" :key="index">
					    {{item.name}}
					</div>
				</div>
				<div class="type_item">
					<div class="title">产品分类：</div>
					<div class="sel_item" @click="handleType('productTypeId',null,null)" :class="{'hover':formobj.productTypeId==null}">全部</div>
					<div class="sel_item" @click="handleType('productTypeId',item.id,item.name)" :class="{'hover':formobj.productTypeId==item.id}" v-for="(item,index) in productType" :key="index">
					    {{item.name}}
					</div>
				</div> -->
				<div class="type_item">
					<div class="title">法律状态：</div>
					<div class="sel_item" @click="handleType('legalId',null,null)" :class="{'hover':formobj.legalId==null}">全部</div>
					<div class="sel_item" @click="handleType('legalId',item.id,item.name)" :class="{'hover':formobj.legalId==item.id}" v-for="(item,index) in legalStatusType" :key="index">
					    {{item.name}}
					</div>
				</div>
				<div class="type_item">
					<div class="title">剩余有限期限：</div>
					<div class="sel_item" @click="handleType('overTimeId',null,null)" :class="{'hover':formobj.overTimeId==null}">全部</div>
					<div class="sel_item" @click="handleType('overTimeId',item.id,item.name)" :class="{'hover':formobj.overTimeId==item.id}" v-for="(item,index) in overTimeType" :key="index">
					    {{item.name}}
					</div>
				</div>
				<div class="type_item">
					<div class="title">申请(专利权)人：</div>
					<div class="inputdiv">
						<input class="fontsize12" v-model="formobj.applicant" placeholder="请输入申请(专利权)人搜索"/>
						<div class="seabtn" @click="handleBtn('init')"><i class="el-icon-search"></i></div>
					</div>
				</div>
				
			</div>
		</div>
		<!-- 列表集合 -->
		<div class="huaxue_deal w1260">
			<div class="deal_item relative" v-for="(item,index) in dealList" :key="index" @click="$util.routerPath('/dealDetail',{uid:item.id})">
				<div class="img"><img :src="item.imgUrl || require('../assets/static/noimg.png')"/></div>
				<div class="title fontsize16 clamp2">{{item.title}}</div>
				<div class="block10"></div>
				<div class="desc fontsize14">申请号：{{item.applyNo}}</div>
				<div class="desc fontsize14">申请人：{{item.applicant}}</div>
				<div class="desc fontsize14">申请日：{{item.applyDate}}</div>
				<div class="more fontsize16">价格 : <span class="fontsize12">{{item.price?"￥":""}}</span>{{item.price || "面议"}}</div>
			</div>
		</div>
		<div class="block30"></div>
		<!-- <div class="huaxue_deal_more" @click="handleLoadMore" v-if="!complete">
			<div class="text fontsize20">增加总数</div>
		</div> -->
		<div class="emptyText fontsize16" v-if="complete&&dealList.length>0">已加载全部~</div>
		<div class="emptyText fontsize16" v-if="dealList.length<=0">暂无相关记录~</div>
		<div class="block30"></div>
		<div class="w1239 phonenone" style="text-align:right">
		    <el-pagination
		          @current-change="handleCurrentChange"
		          :current-page.sync="formobj.currentPage"
		          :page-size="formobj.pageSize"
		          layout="prev, pager, next, jumper"
		          :total="total">
		    </el-pagination>
		</div>
		<div class="w1239 pcnone" style="text-align:center">
		    <el-pagination
			      small
		          @current-change="handleCurrentChange"
		          :current-page.sync="formobj.currentPage"
		          :page-size="formobj.pageSize"
		          layout="prev, pager, next"
		          :total="total">
		    </el-pagination>
		</div>
		<div class="block30"></div>
		<!-- 交易公示 -->
		<div class="block20" v-if="noticeList.length>0"></div>
		<div class="huaxue_home_type huaxue_deal_type w1239" v-if="noticeList.length>0">
			<div class="title fontsize38">交易公示</div>
			<div class="desc fontsize22" @click="$util.routerPath('/infoItem',{type:'jiaoyigongshi'})">查看更多</div>
		</div>
		<div class="deal_notice_box w1239" v-if="noticeList.length>0">
			<div class="notice_item pointer" v-for="(item,index) in noticeList" :key="index" @click="$util.routerPath('/infoDetail',{uid:item.id})">
				<div class="img"><img src="../assets/static/norice_icon.png"></div>
				<div class="title fl clamp fontsize18">{{item.title}}</div>
				<div class="timer fr fontsize18">{{item.releaseTime || item.timer}}</div>
			</div>
		</div>
		
		<div class="block30"></div>
	</div>
</template>

<script>
import {mapState} from 'vuex'
export default {
	data() {
		return {
			dealList:[],
			complete:false,//加载全部
			rightType:[],//产权
			transactionType:[],//产业分类
			legalStatusType:[],//法律
			overTimeType:[],//有效期
			productType:[],//产品分类
			noticeList:[],
			selectobj:{
				rightName:null,//产权类型ID
				technicalFieldName:null,//产业分类ID
				legalName:null,//法律ID
				overTimeName:null,//有效期ID
				productTypeName:null,//产品分类
			},
			formobj:{
				state:0,//上架
				currentPage:1,
				pageSize:8,
				title:"",
				rightId:null,//产权类型ID
				technicalFieldId:null,//产业分类ID
				legalId:null,//法律ID
				overTimeId:null,//有效期ID
				productTypeId:null,//产品分类ID
				useContents:false,//	是否返回文章
				applicant:"",
			},
			total:0,//总数
			bannerObj:{}
		};
	},
	mounted: function() {
		//头图
		this.getBannerFuc(6).then(bannerObj => {
			//console.log(bannerObj.imageUrl)
			if(bannerObj&&bannerObj.imageUrl){
				this.bannerObj = bannerObj
			}
		});
		this.rightTypePage()
		this.transactionRight()
		this.legalStatusPage()
		this.overTimePage()
		this.productTypePage()
		//this.getJiaoyixinxi()
		this.getdataList()
	},
	computed: {
		...mapState(['userInfo', 'isLogin']),
	},
	methods: {
		//分页产权类型
		rightTypePage() {
			var _this = this;
			var params = {
				currentPage: 1,
				pageSize: 88,
				state:0,//状态 0 上架 1 下架
			};
			this.$http.post('frontEnd/transaction/rightTypePage', params).then(function(res) {
				//数据处理
				if (res.code == 200) {
					var records = res.data.records;
					_this.rightType = records
				} else {
					_this.$confirm(res.message, '提示', {
						confirmButtonText: '确定',
						cancelButtonText: '取消',
						type: 'warning'
					}).then(() => {}).catch(() => {});
				}
			})
		},
		//产业分类
		transactionRight() {
			var _this = this;
			var params = {
				currentPage: 1,
				pageSize: 88,
				state:0,//状态 0 上架 1 下架
			};
			this.$http.post('frontEnd/transaction/transactionRight', params).then(function(res) {
				//数据处理
				if (res.code == 200) {
					var records = res.data.records;
					_this.transactionType = records
				} else {
					_this.$confirm(res.message, '提示', {
						confirmButtonText: '确定',
						cancelButtonText: '取消',
						type: 'warning'
					}).then(() => {}).catch(() => {});
				}
			})
		},
		//法律状态
		legalStatusPage() {
			var _this = this;
			var params = {
				currentPage: 1,
				pageSize: 88,
				state:0,//状态 0 上架 1 下架
			};
			this.$http.post('frontEnd/transaction/legalStatusPage', params).then(function(res) {
				//数据处理
				if (res.code == 200) {
					var records = res.data.records;
					_this.legalStatusType = records
				} else {
					_this.$confirm(res.message, '提示', {
						confirmButtonText: '确定',
						cancelButtonText: '取消',
						type: 'warning'
					}).then(() => {}).catch(() => {});
				}
			})
		},
		//有效期
		overTimePage() {
			var _this = this;
			var params = {
				currentPage: 1,
				pageSize: 88,
				state:0,//状态 0 上架 1 下架
			};
			this.$http.post('frontEnd/transaction/overTimePage', params).then(function(res) {
				//数据处理
				if (res.code == 200) {
					var records = res.data.records;
					_this.overTimeType = records
				} else {
					_this.$confirm(res.message, '提示', {
						confirmButtonText: '确定',
						cancelButtonText: '取消',
						type: 'warning'
					}).then(() => {}).catch(() => {});
				}
			})
		},
		//产品分类
		productTypePage() {
			var _this = this;
			var params = {
				currentPage: 1,
				pageSize: 88,
				state:0,//状态 0 上架 1 下架
			};
			this.$http.post('frontEnd/transaction/productType', params).then(function(res) {
				//数据处理
				if (res.code == 200) {
					var records = res.data.records;
					_this.productType = records
				} else {
					_this.$confirm(res.message, '提示', {
						confirmButtonText: '确定',
						cancelButtonText: '取消',
						type: 'warning'
					}).then(() => {}).catch(() => {});
				}
			})
		},
		//跳转页面
		handleCurrentChange(currentPage){
			console.log(currentPage)
			this.formobj.currentPage = currentPage
			this.getdataList()
		},
		//点击加载更多
		handleLoadMore(){
			this.formobj.currentPage +=1
			this.getdataList()
		},
		//点击初始化查询
		handleBtn(type){
			//来自搜索框
			if(type=="btn"){
				if(this.formobj.title){
					this.formobj.currentPage = 1
					this.complete = false
					this.dealList = []
					this.getdataList()
				}else{
					this.$message.info("请输入标题搜索")
				}
			}else{
				this.formobj.currentPage = 1
				this.complete = false
				this.dealList = []
				this.getdataList()
			}
		},
		//点击类型
		handleType(type,id,name){
			if(type=="rightId"){
				this.formobj.rightId = id
				this.selectobj.rightName = name
				
			}else if(type=="technicalFieldId"){
				this.formobj.technicalFieldId = id
				this.selectobj.technicalFieldName = name
				
			}else if(type=="legalId"){
				this.formobj.legalId = id
				this.selectobj.legalName = name
				
			}else if(type=="productTypeId"){
				this.formobj.productTypeId = id
				this.selectobj.productTypeName = name
				
			}else{
				this.formobj.overTimeId = id
				this.selectobj.overTimeName = name	
			}
			//查询
			this.handleBtn("init")
		},
		//加载产权交易列表
		getdataList() {
			var _this = this;
			// if(this.complete){
			// 	console.log("loading all")
			// 	return false;
			// }
			var params = this.formobj
			this.$http.post('frontEnd/transaction/getPage', params).then(function(res) {
				//数据处理
				if (res.code == 200) {
					var records = res.data.records;
					//分页形式
					_this.total = res.data.total
					_this.dealList = records
					//加载更多形式
					// if(records.length>0){
					// 	_this.dealList = _this.dealList.concat(records)
					// }else{
					// 	_this.complete = true
					// }
				} else {
					_this.$confirm(res.message, '提示', {
						confirmButtonText: '确定',
						cancelButtonText: '取消',
						type: 'warning'
					}).then(() => {}).catch(() => {});
				}
			})
		},
		//获取交易公示
		getJiaoyixinxi() {
			var _this = this;
			var params = {
				currentPage: 1,
				pageSize: 10,
			};
			this.$http.post('frontEnd/transaction/getJiaoyixinxi', params).then(function(res) {
				//数据处理
				if (res.code == 200) {
					var records = res.data.records;
					if(records.length>0){
						records.forEach((item,index)=>{
							if(item.createDate){
								item["timer"] = _this.$util.formatdate(_this.$util.getDateTime(item.createDate))
							}
							if(item.releaseTime){
								item["releaseTime"] = _this.$util.formatdate(_this.$util.getDateTime(item.releaseTime))
							}
						})
						_this.noticeList = records
					}
				} else {
					_this.$confirm(res.message, '提示', {
						confirmButtonText: '确定',
						cancelButtonText: '取消',
						type: 'warning'
					}).then(() => {}).catch(() => {});
				}
			})
		},
		
	}
};
</script>
<style lang="scss">

</style>
